import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App" className="text-center" style={{fontSize: "10pt"}}>
      <div style={{marginTop: "50px"}}>na12878@protonmail.com</div>
      <div>Q1 2021: 72%</div>
      <div>Q2 2021: 19.8%</div>
    </div>
  );
}

export default App;
